const fallback = '–';
const locale = 'de-DE';

// Formatters -----------------------------------------------------------

const numberFormatter = new Intl.NumberFormat(locale, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const percentFormatter = new Intl.NumberFormat(locale, {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const currencyFormatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

// Formatting functions -------------------------------------------------

const isNumber = (number?: string | number) =>
  Number.isFinite(typeof number === 'string' ? parseFloat(number) : number);

export function fNumber(number?: string | number) {
  if (!isNumber(number)) return fallback;

  const formatted = numberFormatter.format(Number(number)).replace(',00', '');
  return formatted.match(/,\d0$/i) ? formatted.slice(0, formatted.length - 1) : formatted;
}

export function fPercent(number?: number) {
  if (!isNumber(number)) return fallback;

  return percentFormatter
    .format(Number(number) / 100)
    .replace(',0', '')
    .replace(/\s/g, '\u00A0');
}

export function fMoney(number?: string | number) {
  if (!isNumber(number)) return fallback;

  return numberFormatter.format(Number(number)).replace(',00', '');
}

export function fCurrency(number?: string | number) {
  if (!isNumber(number)) return fallback;

  return fCurrencyLong(number).replace(',00', '');
}

export function fCurrencyLong(number?: string | number) {
  if (!isNumber(number)) return fallback;

  return currencyFormatter
    .format(Number(number))
    .replace(/\s/g, '\u00A0') // add non-breaking space
    .replace('-', '\u2011'); // add non-breaking hyphen
}
